import React from "react"
import { Link } from "gatsby"

//Components
import Header from "../components/navigation/header"
import Footer from "../components/footer"
import Layout from "../components/layout"

// Styles
import styles from "../styles/components/subscribed.module.scss"

const NotFoundPage = () => (
  <div>
    <Header />
    <Layout>
      <div className={styles.content}>
        <h1>
          <span>Thank You</span> For Subscribing
        </h1>
        <p>
          SunTerra installs residential and commercial solar system across Sri
          Lanka. Our central goal is to be the most minimal cost control maker
          on the planet.
        </p>
        <p>
          We have accomplished a generous decrease in the “cost of solar”
          through various forms of increasing productivity, management systems
          and long terms contracts with suppliers.
        </p>
        <Link to="/">
          <button className={styles.btn}>Go Back</button>
        </Link>
      </div>
    </Layout>
    <Footer />
  </div>
)

export default NotFoundPage
